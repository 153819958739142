import axios from "axios";
import { AuthData } from "../types/AuthData";

export interface MetaData {
  current_page: number;
  per_page: number;
  total: number;
}

export type PaginatedResponse<T> = {
  data: T[];
  meta: MetaData;
};

const fetchPaginatedResource = <T>(
  resource: string,
  page: number,
  perPage: number,
  auth: AuthData,
  controller: AbortController
): Promise<PaginatedResponse<T>> => {
  return axios
    .get<PaginatedResponse<T>>(
      `api/${resource}?sortBy=name&sortDesc=asc&perPage=${perPage}&page=${page}`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-auth-info": JSON.stringify(auth),
        },
        signal: controller.signal,
      }
    )
    .then(function (response) {
      return response.data;
    });
};

export default fetchPaginatedResource;
