import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LinearProgressWithLabel from "./LinearProgressWithLabel";
import fetchPaginatedResource from "../api/fetchPaginatedResource";
import { AuthData } from "../types/AuthData";

const PAGE_SIZE = 25;

const fetchAllItems = async <T,>(
  name: string,
  auth: AuthData,
  setItemsFetched: React.Dispatch<React.SetStateAction<T[]>>,
  setTotal: React.Dispatch<React.SetStateAction<number>>,
  controller: AbortController
) => {
  try {
    const firstPage = await fetchPaginatedResource<T>(
      name,
      1,
      PAGE_SIZE,
      auth,
      controller
    );
    let page = firstPage.meta.current_page;
    let perPage = firstPage.meta.per_page;
    let total = firstPage.meta.total;
    setTotal(total);
    setItemsFetched(firstPage.data);
    while (page * perPage < total) {
      page = page + 1;
      const nextPage = await fetchPaginatedResource<T>(
        name,
        page,
        PAGE_SIZE,
        auth,
        controller
      );
      setItemsFetched((old) => [...old, ...nextPage.data]);
    }
  } catch (error) {
    // @ts-ignore
    if (error.name !== "CanceledError") {
      throw error;
    }
  }
};

const FetchDataCard = <T,>({
  title,
  resourceName,
  auth,
  onDataFetched,
  onClick,
}: {
  title: string;
  resourceName: string;
  auth: AuthData;
  onDataFetched: (data: T[]) => void;
  onClick: () => void;
}) => {
  const [data, setItemsFetched] = useState<T[]>([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const controller = new AbortController();
    fetchAllItems<T>(resourceName, auth, setItemsFetched, setTotal, controller);

    return () => {
      controller.abort();
      setItemsFetched([]);
      onDataFetched([]);
      setTotal(0);
    };
  }, [resourceName, auth, onDataFetched]);

  useEffect(() => {
    onDataFetched(data);
  }, [onDataFetched, data]);

  const renderProgress = () =>
    total ? (
      <LinearProgressWithLabel value={(data.length / total) * 100} />
    ) : (
      <LinearProgress />
    );

  return (
    <Card>
      <CardActionArea onClick={onClick}>
        <CardHeader
          title={title}
          subheader={data.length === 0 ? "werden geladen..." : ""}
        ></CardHeader>
        {data.length === 0 ? (
          renderProgress()
        ) : (
          <CardContent>
            <Typography variant="h3">{data.length}</Typography>
          </CardContent>
        )}
      </CardActionArea>
    </Card>
  );
};

export default FetchDataCard;
