import React from "react";
import {
  AppBar,
  Dialog,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TransitionProps } from "@mui/material/transitions";
import { AppUser } from "../types/AppUsers";
import { Child } from "../types/Child";
import { Admin } from "../types/Admin";
import { TeamMember } from "../types/TeamMember";
import { Group } from "../types/Group";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShowDataDialog = ({
  showData,
  onClose,
  children,
  guardians,
  admins,
  teamMembers,
  groups,
}: {
  showData: string | undefined;
  onClose: () => void;
  children: Child[];
  guardians: AppUser[];
  admins: Admin[];
  teamMembers: TeamMember[];
  groups: Group[];
}) => {
  const renderListItems = () => {
    switch (showData) {
      case "Kinder":
        return children.map((child) => (
          <>
            <ListItem key={child.id}>
              <ListItemText
                primary={`${child.first_name} ${child.last_name}`}
                secondary={`${child.birthday || "---"}`}
              />
            </ListItem>
            <Divider key={`${child.id}-divider`} />
          </>
        ));
      case "Nutzer:innen":
        return guardians.map((guardian) => (
          <>
            <ListItem key={guardian.id}>
              <ListItemText
                primary={`${guardian.first_name} ${guardian.last_name}`}
                secondary={`${guardian.email}`}
              />
            </ListItem>
            <Divider key={`${guardian.id}-divider`} />
            <Divider />{" "}
          </>
        ));
      case "Admins":
        return admins.map((admin) => (
          <>
            <ListItem key={admin.id}>
              <ListItemText
                primary={`${admin.first_name} ${admin.last_name}`}
                secondary={`${admin.email}`}
              />
            </ListItem>
            <Divider key={`${admin.id}-divider`} />
          </>
        ));
      case "Teammitglieder":
        return teamMembers.map((teamMember) => (
          <>
            <ListItem key={teamMember.id}>
              <ListItemText
                primary={`${teamMember.first_name} ${teamMember.last_name}`}
                secondary={`${teamMember.email}`}
              />
            </ListItem>
            <Divider key={`${teamMember.id}-divider`} />
          </>
        ));
      case "Gruppen":
        return groups.map((group) => (
          <>
            <ListItem key={group.id}>
              <ListItemText
                primary={`${group.name}`}
                secondary={group.hidden ? "Versteckt" : "Sichtbar"}
              />
            </ListItem>
            <Divider key={`${group.id}-divider`} />
          </>
        ));
      default:
        return <></>;
    }
  };

  return (
    <Dialog
      fullScreen
      open={!!showData}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{
          position: "relative",
          color: "white",
          textTransform: "uppercase",
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {showData}
          </Typography>
        </Toolbar>
      </AppBar>
      <List>{renderListItems()}</List>
    </Dialog>
  );
};

export default ShowDataDialog;
