import axios from "axios";
import { AuthData } from "../types/AuthData";
import { Me } from "../types/Me";

const me = (auth: AuthData, controller: AbortController): Promise<Me> => {
  return axios
    .get<{ data: Me }>(`api/me`, {
      headers: {
        "Content-Type": "application/json",
        "x-auth-info": JSON.stringify(auth),
      },
      signal: controller.signal,
    })
    .then(function (response) {
      return response.data.data;
    });
};

export default me;
