import { Container, Typography } from "@mui/material";
import React from "react";
import { Me } from "../types/Me";

const Welcome = ({ data }: { data: Me }) => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        textAlign: "center",
      }}
      maxWidth="sm"
      className="form"
    >
      <Typography variant="body1">{`Herzlich willkommen ${data.first_name} ${data.last_name}`}</Typography>
    </Container>
  );
};

export default Welcome;
