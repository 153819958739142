import axios from "axios";
import {
  LoginResponse,
  TwoFactorChallengeResponse,
} from "../types/LoginResponse";

const login = (
  username: string,
  password: string
): Promise<LoginResponse | TwoFactorChallengeResponse> => {
  return axios
    .post<LoginResponse | TwoFactorChallengeResponse>(
      "/api/login",
      {
        username,
        password,
      },
      {
        headers: { "Content-Type": "application/json" },
      }
    )
    .then(function (response) {
      return response.data;
    });
};

export const login2fa = (
  challenge: TwoFactorChallengeResponse,
  code: string
): Promise<LoginResponse> => {
  return axios
    .post<LoginResponse>(
      "/api/2fa",
      {
        challenge,
        code,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(function (response) {
      return response.data;
    });
};

export default login;
