import { AuthData, PublicAuthData } from "./AuthData";

export interface LoginResponseData {
  id: string;
  first_name: string;
  last_name: string;
  username: string;
  association: {
    id: string;
    name: string;
    code: string;
    location: string;
  };
}

export interface LoginResponse {
  data: LoginResponseData;
  meta: { status: "success" };
  auth: AuthData;
}

export interface TwoFactorChallenge {
  status: "two_fa_code_required";
  method: "email" | "app";
  token: string;
  email: string;
}
export interface TwoFactorChallengeResponse {
  data: null;
  meta: TwoFactorChallenge;
  auth: PublicAuthData;
}

export const isLoginResponse = (
  data: LoginResponse | TwoFactorChallengeResponse
): data is LoginResponse => data.meta.status === "success";
