import React, { useState } from "react";
import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemProps,
  ListProps,
  ThemeProvider,
  Typography,
  createTheme,
  styled,
} from "@mui/material";
import Login from "./App/Login";
import Welcome from "./App/Welcome";
import FetchDataCard from "./App/FetchDataCard";
import { Child } from "./types/Child";
import { Group } from "./types/Group";
import { AppUser } from "./types/AppUsers";
import { Admin } from "./types/Admin";
import { TeamMember } from "./types/TeamMember";
import ShowDataDialog from "./App/ShowDataDialog";
import ExportExcelLink from "./App/ExportExcelLink";
import { Me } from "./types/Me";
import { AuthData } from "./types/AuthData";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#32cbcb",
    },
    secondary: {
      main: "#ff8158",
    },
    background: {
      default: "#f8ebdb",
    },
  },
  typography: {
    fontFamily: "Lato, Arial",
    h1: {
      fontSize: "4rem",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Lato';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }
      `,
    },
  },
  shape: {
    borderRadius: 12,
  },
});

const BulletListItem = styled(ListItem)<ListItemProps>(({ theme }) => ({
  display: "list-item",
}));

const BulletList = styled(List)<ListProps>(({ theme }) => ({
  listStyleType: "disc",
  marginLeft: "2em",
  "> ::marker": {
    color: "#32cbcb",
  },
}));

const App = () => {
  const [loginData, setLoginData] = useState<{ me: Me; auth: AuthData }>();
  const [children, setChildren] = useState<Child[]>([]);
  const [guardians, setGuardians] = useState<AppUser[]>([]);
  const [admins, setAdmins] = useState<Admin[]>([]);
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [showData, setShowData] = useState<string>();

  const showDataHandler = (data: string) => () => {
    setShowData((current) => (current === data ? undefined : data));
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl" sx={{ backgroundColor: "#f8ebdb" }}>
        <Container
          maxWidth="md"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1em",
            paddingBottom: "4em",
          }}
        >
          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "-4%",
              marginBottom: "-8%",
              width: "40%",
            }}
          >
            <img
              style={{ maxWidth: "100%" }}
              alt="carlo truck"
              src="images/carlo_truck.svg"
            ></img>
          </Container>
          <Typography
            variant="h1"
            sx={{
              paddingTop: "16px",
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            CARE-Umzugshelfer
          </Typography>
          <Typography
            variant="h5"
            sx={{ paddingBottom: "2em", textAlign: "center" }}
          >
            Wir helfen gerne bei Ihrem Umzug zu CARE
          </Typography>

          {loginData ? (
            <>
              <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={12}>
                  <Welcome data={loginData.me} />
                </Grid>
                <Grid item xs={3}>
                  <FetchDataCard
                    title="Kinder"
                    resourceName="children"
                    auth={loginData.auth}
                    onDataFetched={setChildren}
                    onClick={showDataHandler("Kinder")}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FetchDataCard
                    title="Nutzer:nnen"
                    resourceName="app-users"
                    auth={loginData.auth}
                    onDataFetched={setGuardians}
                    onClick={showDataHandler("Nutzer:innen")}
                  />
                </Grid>
                {false && loginData?.auth && (
                  <>
                    <Grid item xs={3}>
                      <FetchDataCard
                        title="Admins"
                        resourceName="admin-users"
                        // @ts-ignore
                        auth={loginData.auth}
                        onDataFetched={setAdmins}
                        onClick={showDataHandler("Admins")}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <FetchDataCard
                        title="Teammitglieder"
                        resourceName="team-members"
                        // @ts-ignore
                        auth={loginData.auth}
                        onDataFetched={setTeamMembers}
                        onClick={showDataHandler("Teammitglieder")}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={3}>
                  <FetchDataCard
                    title="Gruppen"
                    resourceName="groups"
                    auth={loginData.auth}
                    onDataFetched={setGroups}
                    onClick={showDataHandler("Gruppen")}
                  />
                </Grid>
              </Grid>
              <ShowDataDialog
                showData={showData}
                onClose={() => setShowData(undefined)}
                children={children}
                guardians={guardians}
                admins={admins}
                teamMembers={teamMembers}
                groups={groups}
              />
            </>
          ) : (
            <Card>
              <CardHeader
                title="Stayinformed Login"
                subheader="Loggen Sie sich hier mit ihrem stayinformed Zugang ein."
              />
              <CardContent>
                <Login onSuccessfulLogin={setLoginData} />
              </CardContent>
            </Card>
          )}
          <ExportExcelLink
            admins={admins}
            children={children}
            groups={groups}
            guardians={guardians}
            teamMembers={teamMembers}
          />
          <Card>
            <CardHeader title="Achtung" />
            <CardContent>
              <Typography>
                Einige Informationen werden nicht in stayinformed gepflegt, sie
                können daher auch nicht exportiert werden. Sie können diese
                Informationen nach dem Export in der Excel Tabelle oder nach dem
                Import in CARE anpassen.
              </Typography>
              <Typography sx={{ marginTop: "1em" }}>
                Einige Felder sind in CARE Pflichtfelder aber in stayinformed
                nicht vorhanden oder optional. Sie werden automatisch
                folgendermaßen gesetzt:
              </Typography>
              <BulletList>
                <BulletListItem>Straße (unbekannt)</BulletListItem>
                <BulletListItem>PLZ (unbekannt)</BulletListItem>
                <BulletListItem>Ort (unbekannt)</BulletListItem>
                <BulletListItem>Telefonnummer (unbekannt)</BulletListItem>
                <BulletListItem>
                  E-Mail-Addresse (unbekannt, wenn nicht bekannt)
                </BulletListItem>
                <BulletListItem>Geschlecht (divers)</BulletListItem>
                <BulletListItem>
                  Geburtstag des Kindes (2000-01-01)
                </BulletListItem>
                <BulletListItem>
                  Beziehung der App User zum Kind (Sonstige Person)
                </BulletListItem>
              </BulletList>
              <Typography></Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Datenschutz" />
            <CardContent>
              <Typography variant="body1">
                Diese Seite greift mit Hilfe Ihres stayinformed Logins auf Ihre
                stayinformed Instanz zu und lädt Informationen über Kinder, App
                Nutzer:innen, Admins, Teammitglieder und Gruppen. Daraus
                erstellt sie ein Excel Dokument, dass Sie in CARE für den Import
                von Kindern nutzen können.
              </Typography>
              <br />
              <Typography variant="body1">
                Informationen aus Ihrer stayinformed Instanz werden weder auf
                unseren Servern, noch sonst irgendwo zwischengespeichert.
              </Typography>
              <br />
              <Typography variant="body1">
                Temporäre Login-Informationen werden in Ihrem Browser
                zwischengespeichert, damit die stayinformed Login-Daten nicht
                mehrfach eingegeben werden müssen. Diese Informationen laufen
                innerhalb von einigen Minuten ab und sind danach nutzlos.
                Verwenden Sie diese Seite trotzdem nur auf Geräten denen Sie
                vertrauen.
              </Typography>
            </CardContent>
          </Card>
          <Card>
            <CardHeader title="Impressum" />
            <CardContent>
              <Typography variant="overline">
                Verantwortlich für den Inhalt:
              </Typography>
              <Typography>
                Carlo & Friends GmbH Kleine Freiheit 68 22767 Hamburg
              </Typography>
              <br />
              <Typography variant="overline">Kontakt:</Typography>
              <Typography>
                Telefon: +49 (0)40 – 22852110-0
                <br />
                Telefax: +49 (0)40 – 22852114
                <br />
                E-Mail: kontakt@carloandfriends.de
              </Typography>
              <br />
              <Typography variant="overline">
                CERT (Cyber Emergency Response Team):
              </Typography>
              <Typography>
                IT-Sicherheitsprobleme können 24/7 gemeldet werden an:
                cert@carloandfriends.de
              </Typography>
              <br />
              <Typography variant="overline">Geschäftsführer:</Typography>
              <Typography>Karsten Glied</Typography>
              <br />
              <Typography variant="overline">Sitz der Gesellschaft:</Typography>
              <Typography>
                Hamburg Eingetragen beim Amtsgericht Hamburg, HRB 165409
              </Typography>
              <br />
              <Typography variant="overline">
                Inhaltlich verantwortlich gemäß § 5 TMG:
              </Typography>
              <Typography>
                Karsten Glied, Kleine Freiheit 68, 22767 Hamburg
              </Typography>
              <br />
              <Typography variant="overline">
                Umsatzsteuer-Identifikationsnummer gemäß §27 a
                Umsatzsteuergesetz:
              </Typography>
              <Typography>DE335375876</Typography>
            </CardContent>
          </Card>
        </Container>
      </Container>
    </ThemeProvider>
  );
};

export default App;
